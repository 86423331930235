module mainapp {


    export class Init {


        bootstrap() {


            return;


        }


    }


}
